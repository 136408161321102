import { VBtn } from 'vuetify/lib/components/VBtn';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"demo-space-x"},[_c(VTooltip,{attrs:{"top":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" scale transition ")])]}}])},[_c('span',[_vm._v("Scale Transition")])]),_c(VTooltip,{attrs:{"top":"","transition":"scroll-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" scroll X transition ")])]}}])},[_c('span',[_vm._v("Scroll X Transition")])]),_c(VTooltip,{attrs:{"top":"","transition":"scroll-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" scroll y transition ")])]}}])},[_c('span',[_vm._v("Scroll Y Transition")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }