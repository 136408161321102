import { VBtn } from 'vuetify/lib/components/VBtn';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"demo-space-x"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" default ")])]}}])},[_c('span',[_vm._v("Tooltip")])]),_vm._l((_vm.colorTooltipList),function(tooltip){return _c(VTooltip,{key:tooltip,attrs:{"bottom":"","color":tooltip},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":tooltip,"dark":"","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(tooltip)+" ")])]}}],null,true)},[_c('span',[_vm._v("Tooltip")])])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }