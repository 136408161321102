import { VBtn } from 'vuetify/lib/components/VBtn';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"demo-space-x"},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","dark":"","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" Tooltip on Top ")])]}}])},[_c('span',[_vm._v("Top tooltip")])]),_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","dark":"","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" Tooltip on Right ")])]}}])},[_c('span',[_vm._v("Right tooltip")])]),_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","dark":"","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" Tooltip on Left ")])]}}])},[_c('span',[_vm._v("Left tooltip")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","dark":"","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" Tooltip on Bottom ")])]}}])},[_c('span',[_vm._v("Bottom tooltip")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }