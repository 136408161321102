import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"demo-space-x"},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Button ")])]}}])},[_c('span',[_vm._v("Tooltip")])]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VAvatar,_vm._g(_vm._b({attrs:{"color":"info"}},'v-avatar',attrs,false),on),[_c(VImg,{attrs:{"src":require("@/assets/images/avatars/4.png")}})],1)]}}])},[_c('span',[_vm._v("Tooltip on Avatar")])]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"large":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiAccountOutline)+" ")])]}}])},[_c('span',[_vm._v("Tooltip on Icon")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }