import { VBtn } from 'vuetify/lib/components/VBtn';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"demo-space-x"},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"outlined":"","color":"primary"}},'v-btn',attrs,false),on),[_c('span',[_vm._v("Open On Hover")])])]}}])},[_c('span',[_vm._v("Open On Hover")])]),_c(VTooltip,{attrs:{"open-on-click":true,"open-on-hover":false,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"outlined":"","color":"primary"},on:{"click":on.click}},'v-btn',attrs,false),[_c('span',[_vm._v("Open On click")])])]}}])},[_c('span',[_vm._v("Open On click")])]),_c(VTooltip,{attrs:{"open-on-focus":true,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"outlined":"","color":"primary"}},'v-btn',attrs,false),on),[_c('span',[_vm._v("Open On Hover + Focus")])])]}}])},[_c('span',[_vm._v("Open On Hover + Focus")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }