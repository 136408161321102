import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"demo-space-x"},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){_vm.isTooltipVisiable = !_vm.isTooltipVisiable}}},[_vm._v(" toggle tooltip ")]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiInstagram)+" ")])]}}]),model:{value:(_vm.isTooltipVisiable),callback:function ($$v) {_vm.isTooltipVisiable=$$v},expression:"isTooltipVisiable"}},[_c('span',[_vm._v("Programmatic tooltip")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }